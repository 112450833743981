import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getCategoryTitle } from 'state/PlaylistDirectory/selectors';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

function Head() {
  const categoryTitle = useSelector(getCategoryTitle);
  const translate = useTranslate();

  const description = translate(
    'Discover and stream the best {categoryTitle} playlists, handcrafted by the music experts at iHeart.',
    { categoryTitle: categoryTitle.toLowerCase() as string },
  );
  const keywords = translate(
    '{categoryTitle}, {categoryTitle} Playlists, Stream {categoryTitle} Music, Play {categoryTitle} Songs, Top {categoryTitle} Playlists, Best { categoryTitle} Playlists',
    { categoryTitle: categoryTitle as string },
  );
  const title = translate(
    'Top {categoryTitle} Playlists; Find Your Perfect Music Mix',
    {
      categoryTitle: categoryTitle as string,
    },
  );

  return (
    <>
      <GenericHead
        description={description}
        ogType="music.playlist"
        title={title}
      />
      <Helmet>
        <meta content={title} itemProp="name" />
        <meta content={keywords} name="keywords" />
      </Helmet>
    </>
  );
}

export default Head;
