import Head from './Head';
import PageBody, { ViewName } from 'views/PageBody';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlaylistCategory from 'components/PlaylistCategory';
import useTranslate from 'contexts/TranslateContext/useTranslate';

export type Props = {
  categoryProp: {
    collection: string;
    facets: string;
  };
  categoryTitle: string;
  params: {
    category: string;
    collection: 'decades' | 'genre-playlists' | 'perfect-for';
    subcategory: string;
  };
};

function PlaylistSubDirectory({ categoryProp, categoryTitle, params }: Props) {
  const translate = useTranslate();

  return (
    <>
      <Head />
      <PageBody
        backLink="/playlist/"
        dataTest={ViewName.PlaylistSubDirectory}
        title={translate('Playlists')}
      >
        <PlaylistCategory
          category={categoryProp}
          name={categoryTitle}
          playedFrom={
            {
              decades: PLAYED_FROM.DIR_PLAYLIST_DECADE,
              'genre-playlists': PLAYED_FROM.DIR_PLAYLIST_GENRE,
              'perfect-for': PLAYED_FROM.DIR_PLAYLIST_PERFECTFOR,
            }[params.collection]
          }
          showAllTiles
          tilesInRow={3}
          titleLines={1}
        />
      </PageBody>
    </>
  );
}

export default PlaylistSubDirectory;
